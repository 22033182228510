import { useMemo } from "react";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import { Box, Tooltip, IconButton } from "@mui/material";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { mkConfig, generateCsv, download } from "export-to-csv";
import { formatDate } from "../../utils/format";
import { useTranslation } from "react-i18next";
import Button from "../../widgets/Button";

const csvConfig = mkConfig({
  fieldSeparator: ",",
  decimalSeparator: ".",
  useKeysAsHeaders: true,
});

export const WithdrawalRequestTable = ({
  data,
  handleRequest,
  handleReject,
  isLoading,
}) => {
  const { t } = useTranslation();

  const columns = useMemo(
    () => [
      {
        accessorKey: "id",
        header: t("Serial No."),
        enableEditing: false,
        size: 40,
        Cell: ({ row }) => <span>{row.index + 1}</span>,
      },
      {
        accessorKey: "user_name",
        header: t("Username"),
        Cell: ({ row, item }) => (
          <span>{row.original.user_name ? row.original.user_name : "-"}</span>
        ),
      },
      // {
      //   accessorKey: "email",
      //   header: t("Email"),
      //   Cell: ({ row, item }) => (
      //     <span>{row.original.email ? row.original.email : "-"}</span>
      //   ),
      // },

      {
        accessorKey: "coin_amount",
        header: t("Amount"),
        Cell: ({ row, item }) => <span>{row.original.coin_amount}</span>,
      },
      {
        accessorKey: "trans_type",
        header: t("Withdrawal Type"),
        Cell: ({ row, item }) => (
          <span>{row.original.trans_type?.toUpperCase()}</span>
        ),
      },
      {
        accessorKey: "approve",
        header: t("Status"),
        Cell: ({ row, item }) => (
          <span>
            {row.original.approve === "0"
              ? "Pending"
              : row.original.approve === "1"
              ? "Approved"
              : "Rejected"}
          </span>
        ),
      },

      {
        accessorKey: "createdAt",
        header: t("Date"),
        enableEditing: false,
        Cell: ({ row }) => <span>{formatDate(row.original.created)}</span>,
      },
      {
        accessorKey: "Actions",
        header: t("Actions"),
        enableEditing: false,
        Cell: ({ row }) => (
          <>
            {row.original.approve === "-1" ? (
              "-"
            ) : (
              <Box sx={{ display: "flex", gap: "1rem" }}>
                <Tooltip title={t("Reject")}>
                  <IconButton>
                    <Button
                      isLoading={isLoading}
                      className="btn btn-success"
                      onClick={() => handleRequest(row.original, "0")}
                    >
                      Approve
                    </Button>
                  </IconButton>
                </Tooltip>

                <Tooltip title={t("Reject")}>
                  <IconButton
                    color={row.original.status === "1" ? "success" : "error"}
                  >
                    {/* {row.original.status === "1" ? <ToggleOnIcon /> : <ToggleOffIcon />} */}
                    <button
                      className="btn btn-danger"
                      onClick={() => handleReject(row.original)}
                    >
                      Reject
                    </button>
                  </IconButton>
                </Tooltip>
              </Box>
            )}
          </>
        ),
      },
    ],
    [data]
  );

  const handleExportRows = (rows) => {
    const rowData = rows.map((row) => row.original);
    const csv = generateCsv(csvConfig)(rowData);
    download(csvConfig)(csv);
  };

  const handleExportData = () => {
    const csv = generateCsv(csvConfig)(data);
    download(csvConfig)(csv);
  };

  const table = useMaterialReactTable({
    columns,
    data: data ? data : [],
    // enableGrouping: true,
    enableStickyHeader: true,
    enableStickyFooter: true,
    enableRowSelection: false,
    columnFilterDisplayMode: "popover",
    paginationDisplayMode: "pages",
    positionToolbarAlertBanner: "bottom",
    enableEditing: false,
    getRowId: (row) => row.id,
    // renderRowActions: ({ row, table }) => (
    //   <Box sx={{ display: "flex", gap: "1rem" }}>
    //     <Tooltip
    //       title={t("Edit")}
    //       disabled={row.original.status === "1" ? false : true}
    //     >
    //       <IconButton>
    //         <Button className="btn btn-success">Approve</Button>
    //       </IconButton>
    //     </Tooltip>

    //     <Tooltip title={t("Reject")}>
    //       <IconButton color={row.original.status === "1" ? "success" : "error"}>
    //         {/* {row.original.status === "1" ? <ToggleOnIcon /> : <ToggleOffIcon />} */}
    //         <button className="btn btn-danger">Reject</button>
    //       </IconButton>
    //     </Tooltip>
    //   </Box>
    // ),
    // renderTopToolbarCustomActions: ({ table }) => (
    //   <Box
    //     sx={{
    //       display: "flex",
    //       gap: "16px",
    //       padding: "8px",
    //       flexWrap: "wrap",
    //     }}
    //   >
    //     <Button
    //       //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
    //       onClick={handleExportData}
    //       startIcon={<FileDownloadIcon />}
    //     >
    //       {t("Export All")}
    //     </Button>

    //     <Button
    //       disabled={
    //         !table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()
    //       }
    //       //only export selected rows
    //       onClick={() => handleExportRows(table.getSelectedRowModel().rows)}
    //       startIcon={<FileDownloadIcon />}
    //     >
    //       {t("Export Selected")}
    //     </Button>
    //   </Box>
    // ),
  });

  return <MaterialReactTable table={table} />;
};
