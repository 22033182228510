import { apiSlice } from "./api";
import {
  getWithdrawalApi,
  postWithdrawalApi,
} from "../components/constant/Api";

export const withdrawalApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    approveWithdrawal: builder.mutation({
      query: (post) => ({
        url: postWithdrawalApi,
        method: "POST",
        body: post,
      }),

      invalidatesTags: (_) => [""],
    }),

    getWithdrawal: builder.query({
      query: () => ({
        url: getWithdrawalApi,
        method: "GET",
      }),
      transformResponse: (response, meta, arg) => {
        return response.status ? response?.data ?? [] : [];
      },
    }),
  }),
});

export const { useGetWithdrawalQuery, useApproveWithdrawalMutation } =
  withdrawalApi;
