import React, { useEffect, useState } from "react";
import Button from "../../components/widgets/Button";
import Modal from "react-bootstrap/Modal";
import { useNavigate, NavLink } from "react-router-dom";
import Loader from "./Loader";
import { useGetUserPermissionsMutation } from "../../redux/usersApi";
import { useSelector, useDispatch } from "react-redux";
import { setCredentials, logout } from "../../redux/authSlice";
import { apiSlice } from "../../redux/api";
import { useTranslation } from "react-i18next";
import {
  Dashboard,
  Group,
  CurrencyBitcoin,
  Euro,
  Addchart,
  AdminPanelSettings,
  AddBusiness,
  Summarize,
  RequestQuote,
  MoneyOff,
  AccountBalanceWallet,
  Assessment,
  AutoGraph,
  Forum,
  Logout,
  PriceCheck,
} from "@mui/icons-material";

function Sidebar() {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const { permissions } = useSelector((state) => state.auth);

  const [getPermissions, { isLoading, isSuccess }] =
    useGetUserPermissionsMutation();
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const checkPermissions = async () => {
    const userPermissions = await getPermissions().unwrap();
    dispatch(setCredentials(userPermissions));
  };

  // console.log(permissions, "permissions");

  useEffect(() => {
    if (!permissions) {
      dispatch(apiSlice.util.resetApiState());
      dispatch(logout());
      handleClose();
      navigate("/login");
      return;
    }
  }, [permissions]);

  const handleLogout = () => {
    dispatch(apiSlice.util.resetApiState());
    dispatch(logout());
    handleClose();
    navigate("/login");
  };

  useEffect(() => {
    checkPermissions();
  }, []);

  if (isLoading && isSuccess) {
    return (
      <div className="left-sidebar">
        <Loader />
        <li className="text-center mt-3 p-20 upgrade-btn">
          <button
            href="#"
            className="btn w-100 btn1 text-white"
            target="_blank"
            onClick={handleShow}
          >
            <i className=" fas fa-sign-out-alt" /> {t("Logout")}
          </button>
        </li>
      </div>
    );
  }

  return (
    <>
      <div className="left-sidebar" onClick={checkPermissions}>
        <div>
          <div className="scroll-sidebar">
            <nav className="sidebar-nav">
              <ul id="sidebarnav">
                <li className="sidebar-item pt-2">
                  <NavLink
                    // className="sidebar-link waves-effect waves-dark sidebar-link "
                    to="/dashboard"
                    aria-expanded="false"
                    className={({ isActive }) =>
                      isActive
                        ? "active sidebar-link waves-effect waves-dark sidebar-link"
                        : "sidebar-link waves-effect waves-dark sidebar-link"
                    }
                  >
                    {/* <i className="fa fa-home" aria-hidden="true" /> */}
                    <Dashboard fontSize="small" />
                    <span className="hide-menu">{t("Dashboard")}</span>
                  </NavLink>
                </li>

                <li className="sidebar-item">
                  <NavLink
                    className={({ isActive }) =>
                      isActive
                        ? " sidebar-link waves-effect waves-dark sidebar-link"
                        : "sidebar-link waves-effect waves-dark sidebar-link"
                    }
                    data-bs-toggle="collapse"
                    data-bs-target="#demo2"
                    to={
                      permissions["admin"]?.status
                        ? "/admins"
                        : permissions["broker"]?.status
                        ? "/brokers"
                        : "/ibs"
                    }
                  >
                    {/* <i className="fa fa-users" /> */}
                    <Group fontSize="small" />
                    <span className="hide-menu">{t("Users")}</span>
                  </NavLink>
                  <div className="collapse" id="demo2">
                    <ul className="submenu">
                      {permissions["admin"]?.status && (
                        <li>
                          <NavLink className="sidebar-link" to="/admins">
                            {t("Admins")}
                          </NavLink>
                        </li>
                      )}
                      {permissions["broker"]?.status && (
                        <li>
                          <NavLink className="sidebar-link" to="/brokers">
                            {t("Brokers")}
                          </NavLink>
                        </li>
                      )}
                      {permissions["ib"]?.status && (
                        <li>
                          <NavLink className="sidebar-link" to="/ibs">
                            {t("Introducing Broker(IB)")}
                          </NavLink>
                        </li>
                      )}
                      {permissions["ib"]?.permissions.subIb?.read && (
                        <li>
                          <NavLink className="sidebar-link" to="/subIbs">
                            {t("Customers")}
                          </NavLink>
                        </li>
                      )}
                    </ul>
                  </div>
                </li>

                {permissions["crypto"]?.status && (
                  <li className="sidebar-item">
                    <NavLink
                      to="#"
                      className={({ isActive }) =>
                        isActive
                          ? " sidebar-link waves-effect waves-dark sidebar-link"
                          : "sidebar-link waves-effect waves-dark sidebar-link"
                      }
                      data-bs-toggle="collapse"
                      data-bs-target="#demo"
                    >
                      {/* <i className="fa fa-btc" /> */}
                      <CurrencyBitcoin fontSize="small" />
                      <span className="hide-menu">
                        {t("Crypto Management")}
                      </span>
                    </NavLink>
                    <div className="collapse" id="demo">
                      <ul className="submenu">
                        {permissions["crypto"]?.permissions?.coins?.read && (
                          <li>
                            <NavLink className="sidebar-link" to="/crypto">
                              {t("Coins")}
                            </NavLink>
                          </li>
                        )}
                        {permissions["crypto"]?.permissions?.pairs?.read && (
                          <li>
                            <NavLink
                              className="sidebar-link"
                              to="/crypto-pairs"
                            >
                              {t("Pairs")}
                            </NavLink>
                          </li>
                        )}
                        {permissions["crypto"]?.permissions?.exchangeManagement
                          ?.read && (
                          <li>
                            <NavLink
                              className="sidebar-link"
                              to="/exchange-management"
                            >
                              {t("Exchange Management")}
                            </NavLink>
                          </li>
                        )}
                        {/* <li>
                          <NavLink
                            className="sidebar-link"
                            to="/coins-commission"
                          >
                            Commission
                          </NavLink>
                        </li> */}
                        {permissions["crypto"]?.permissions?.risk?.read && (
                          <li>
                            <NavLink className="sidebar-link" to="/coins-risk">
                              {t("Risk")}
                            </NavLink>
                          </li>
                        )}

                        {permissions["crypto"]?.permissions?.tradeFee?.read && (
                          <li>
                            <NavLink className="sidebar-link" to="/commission">
                              {t("Trade Fees")}
                            </NavLink>
                          </li>
                        )}
                        {permissions["crypto"]?.permissions?.spread?.read && (
                          <li>
                            <NavLink className="sidebar-link" to="/spread">
                              {t("Spreads")}
                            </NavLink>
                          </li>
                        )}
                        {permissions["crypto"]?.permissions?.urlManagement
                          ?.read && (
                          <li>
                            <NavLink
                              className="sidebar-link"
                              to="/url-management"
                            >
                              {t("URL Management")}
                            </NavLink>
                          </li>
                        )}
                      </ul>
                    </div>
                  </li>
                )}

                {permissions["fx"]?.status && (
                  <li className="sidebar-item">
                    <NavLink
                      className={({ isActive }) =>
                        isActive
                          ? " sidebar-link waves-effect waves-dark sidebar-link"
                          : "sidebar-link waves-effect waves-dark sidebar-link"
                      }
                      data-bs-toggle="collapse"
                      data-bs-target="#demo5"
                      to={"#"}
                    >
                      {/* <i class="fa fa-first-order" aria-hidden="true"></i> */}
                      <Euro fontSize="small" />
                      <span className="hide-menu">
                        {t("FX & Equity Management")}
                      </span>
                    </NavLink>
                    <div className="collapse" id="demo5">
                      <ul className="submenu">
                        {permissions["fx"]?.permissions?.listingCurrency
                          ?.read && (
                          <li>
                            <NavLink className="sidebar-link" to="/fx-currency">
                              {t("Listing Currency")}
                            </NavLink>
                          </li>
                        )}
                        {permissions["fx"]?.permissions?.commission?.read && (
                          <li>
                            <NavLink
                              className="sidebar-link"
                              to="/fx-commission"
                            >
                              {t("Trade Fee")}
                            </NavLink>
                          </li>
                        )}
                        {permissions["fx"]?.permissions?.riskManagement
                          ?.read && (
                          <li>
                            <NavLink className="sidebar-link" to="/fx-risk">
                              {t("Risk Management")}
                            </NavLink>
                          </li>
                        )}
                        {permissions["fx"]?.permissions?.leverageManagement
                          ?.read && (
                          <li>
                            <NavLink className="sidebar-link" to="/leverage">
                              {t("Leverage Management")}
                            </NavLink>
                          </li>
                        )}
                        {permissions["fx"]?.permissions?.spread?.read && (
                          <li>
                            <NavLink className="sidebar-link" to="/spread-fx">
                              {t("Spread")}
                            </NavLink>
                          </li>
                        )}
                        {permissions["fx"] && (
                          <li>
                            <NavLink
                              className="sidebar-link"
                              to="/manage-interest-fx"
                            >
                              {t("Manage Interest Rate")}
                            </NavLink>
                          </li>
                        )}
                      </ul>
                    </div>
                  </li>
                )}

                {/* {permissions["equity"]?.status && (
                  <li className="sidebar-item">
                    <NavLink
                      className={({ isActive }) =>
                        isActive
                          ? " sidebar-link waves-effect waves-dark sidebar-link"
                          : "sidebar-link waves-effect waves-dark sidebar-link"
                      }
                      data-bs-toggle="collapse"
                      data-bs-target="#demo6"
                      to={"#"}
                    >
                      <Addchart fontSize="small" />
                      <span className="hide-menu">Equity Management</span>
                    </NavLink>
                    <div className="collapse" id="demo6">
                      <ul className="submenu">
                        {permissions["equity"]?.permissions?.listingCurrency
                          ?.read && (
                          <li>
                            <NavLink
                              className="sidebar-link"
                              to="/equity-stocks"
                            >
                              Listing Stocks
                            </NavLink>
                          </li>
                        )}
                        {permissions["equity"]?.permissions?.listingCurrency
                          ?.read && (
                          <li>
                            <NavLink
                              className="sidebar-link"
                              to="/equity-commission"
                            >
                              Commission
                            </NavLink>
                          </li>
                        )}
                        {permissions["equity"]?.permissions?.listingCurrency
                          ?.read && (
                          <li>
                            <NavLink className="sidebar-link" to="/equity-risk">
                              Risk Management
                            </NavLink>
                          </li>
                        )}
                      </ul>
                    </div>
                  </li>
                )} */}

                {permissions["kyc"]?.status && (
                  <li className="sidebar-item">
                    <NavLink
                      className={({ isActive }) =>
                        isActive
                          ? " sidebar-link waves-effect waves-dark sidebar-link"
                          : "sidebar-link waves-effect waves-dark sidebar-link"
                      }
                      data-bs-toggle="collapse"
                      data-bs-target="#demo4"
                      to={"#"}
                    >
                      {/* <i class="fa fa-id-card-o" aria-hidden="true"></i> */}
                      <AdminPanelSettings fontSize="small" />
                      <span className="hide-menu">{t("Kyc Management")}</span>
                    </NavLink>
                    <div className="collapse" id="demo4">
                      <ul className="submenu">
                        {permissions["kyc"]?.permissions?.pendingKyc?.read && (
                          <li>
                            <NavLink className="sidebar-link" to="/kyc/pending">
                              {t("Pending KYC")}
                            </NavLink>
                          </li>
                        )}
                        {permissions["kyc"]?.permissions?.completedKyc
                          ?.read && (
                          <li>
                            <NavLink
                              className="sidebar-link"
                              to="/kyc/complete"
                            >
                              {t("Completed KYC")}
                            </NavLink>
                          </li>
                        )}
                        {permissions["kyc"]?.permissions?.rejectedKyc?.read && (
                          <li>
                            <NavLink className="sidebar-link" to="/kyc/reject">
                              {t("Rejected KYC")}
                            </NavLink>
                          </li>
                        )}
                      </ul>
                    </div>
                  </li>
                )}

                <li className="sidebar-item">
                  <NavLink
                    className={({ isActive }) =>
                      isActive
                        ? " sidebar-link waves-effect waves-dark sidebar-link"
                        : "sidebar-link waves-effect waves-dark sidebar-link"
                    }
                    data-bs-toggle="collapse"
                    data-bs-target="#demo44"
                    to={"#"}
                  >
                    <PriceCheck fontSize="small" />
                    <span className="hide-menu">
                      {t("Withdrawal Management")}
                    </span>
                  </NavLink>
                  <div className="collapse" id="demo44">
                    <ul className="submenu">
                      <li>
                        <NavLink
                          className="sidebar-link"
                          to="/withdrawal-request"
                        >
                          {t("Withdrawal")}
                        </NavLink>
                      </li>
                    </ul>
                  </div>
                </li>

                {permissions["vendor"]?.status && (
                  <li className="sidebar-item">
                    <NavLink
                      className={({ isActive }) =>
                        isActive
                          ? " sidebar-link waves-effect waves-dark sidebar-link"
                          : "sidebar-link waves-effect waves-dark sidebar-link"
                      }
                      data-bs-toggle="collapse"
                      data-bs-target="#demo3"
                      to={"#"}
                    >
                      {/* <i className="fa fa-industry" aria-hidden="true"></i> */}
                      <AddBusiness fontSize="small" />
                      <span className="hide-menu">
                        {t("Vendor Management")}
                      </span>
                    </NavLink>
                    <div className="collapse" id="demo3">
                      <ul className="submenu">
                        {permissions["kyc"]?.status && (
                          <li>
                            <NavLink className="sidebar-link" to="/kyc-vendor">
                              {t("Vendor Kyc Management")}
                            </NavLink>
                          </li>
                        )}
                        {permissions["vendor"]?.permissions?.clearingManagement
                          ?.read && (
                          <li>
                            <NavLink
                              className="sidebar-link"
                              to="/clearing-house"
                            >
                              {t("Clearing Management")}
                            </NavLink>
                          </li>
                        )}
                        {permissions["vendor"]?.permissions?.fxManagement
                          ?.read && (
                          <li>
                            <NavLink
                              className="sidebar-link"
                              to="/data-provider"
                            >
                              {t("Fx Vendor")}
                            </NavLink>
                          </li>
                        )}
                      </ul>
                    </div>
                  </li>
                )}

                {permissions["transactions"]?.status && (
                  <li className="sidebar-item">
                    <NavLink
                      className={({ isActive }) =>
                        isActive
                          ? " sidebar-link waves-effect waves-dark sidebar-link"
                          : "sidebar-link waves-effect waves-dark sidebar-link"
                      }
                      data-bs-toggle="collapse"
                      data-bs-target="#demo7"
                      to={"#"}
                    >
                      <Summarize fontSize="small" />
                      <span className="hide-menu">
                        {t("Transaction Report")}
                      </span>
                    </NavLink>
                    <div className="collapse" id="demo7">
                      <ul className="submenu">
                        {permissions["transactions"]?.permissions?.crypto
                          ?.read && (
                          <li>
                            <NavLink
                              // className="sidebar-link"
                              // to="/transaction-crypto"
                              className={({ isActive }) =>
                                isActive
                                  ? " sidebar-link waves-effect waves-dark sidebar-link"
                                  : "sidebar-link waves-effect waves-dark sidebar-link"
                              }
                              data-bs-toggle="collapse"
                              data-bs-target="#demo12"
                              to={"/buy-orders"}
                            >
                              {t("Crypto")}
                            </NavLink>
                            <li className="sidebar-item">
                              <div className="collapse" id="demo12">
                                <ul className="submenu">
                                  <li>
                                    <NavLink
                                      className="sidebar-link"
                                      to="/buy-orders"
                                    >
                                      {t("Buy Orders")}
                                    </NavLink>
                                  </li>
                                  <li>
                                    <NavLink
                                      className="sidebar-link"
                                      to="/sell-orders"
                                    >
                                      {t("Sell Orders")}
                                    </NavLink>
                                  </li>
                                </ul>
                              </div>
                            </li>
                          </li>
                        )}
                        {permissions["transactions"]?.permissions?.fx?.read && (
                          <li>
                            <NavLink
                              className="sidebar-link"
                              to="/transaction-fx"
                              data-bs-toggle="collapse"
                              data-bs-target="#demo14"
                            >
                              {t("Fx")}
                            </NavLink>
                            <li className="sidebar-item">
                              <div className="collapse" id="demo14">
                                <ul className="submenu">
                                  <li>
                                    <NavLink
                                      className="sidebar-link"
                                      to="/buy-orders-fx"
                                    >
                                      {t("Buy Orders")}
                                    </NavLink>
                                  </li>
                                  <li>
                                    <NavLink
                                      className="sidebar-link"
                                      to="/sell-orders-fx"
                                    >
                                      {t("Sell Orders")}
                                    </NavLink>
                                  </li>
                                </ul>
                              </div>
                            </li>
                          </li>
                        )}
                        {permissions["transactions"]?.permissions?.fx?.read && (
                          <li>
                            <NavLink
                              className="sidebar-link"
                              to="/transaction-equity"
                              data-bs-toggle="collapse"
                              data-bs-target="#demo15"
                            >
                              {t("Equity")}
                            </NavLink>
                            <li className="sidebar-item">
                              <div className="collapse" id="demo15">
                                <ul className="submenu">
                                  <li>
                                    <NavLink
                                      className="sidebar-link"
                                      to="/buy-orders-eq"
                                    >
                                      {t("Buy Orders")}
                                    </NavLink>
                                  </li>
                                  <li>
                                    <NavLink
                                      className="sidebar-link"
                                      to="/sell-orders-eq"
                                    >
                                      {t("Sell Orders")}
                                    </NavLink>
                                  </li>
                                </ul>
                              </div>
                            </li>
                          </li>
                        )}
                      </ul>
                    </div>
                  </li>
                )}

                {permissions["commission"]?.status && (
                  <li className="sidebar-item">
                    <NavLink
                      className={({ isActive }) =>
                        isActive
                          ? " sidebar-link waves-effect waves-dark sidebar-link"
                          : "sidebar-link waves-effect waves-dark sidebar-link"
                      }
                      data-bs-toggle="collapse"
                      data-bs-target="#demo8"
                      to={"#"}
                    >
                      <RequestQuote fontSize="small" />
                      <span className="hide-menu">
                        {t("Commission Report")}
                      </span>
                    </NavLink>
                    <div className="collapse" id="demo8">
                      <ul className="submenu">
                        {permissions["commission"]?.permissions?.crypto
                          ?.read && (
                          <li>
                            <NavLink
                              className="sidebar-link"
                              to="/commission-crypto"
                              data-bs-toggle="collapse"
                              data-bs-target="#demo111"
                            >
                              {t("Crypto")}
                            </NavLink>
                            <div className="collapse" id="demo111">
                              <ul className="submenu">
                                <li>
                                  <NavLink
                                    className="sidebar-link"
                                    to="/users-commission-crypto"
                                  >
                                    {t("Users commission")}
                                  </NavLink>
                                </li>
                                <li>
                                  <NavLink
                                    className="sidebar-link"
                                    to="/buy-commission-collections"
                                  >
                                    {t("Buy commission collections")}
                                  </NavLink>
                                </li>
                                <li>
                                  <NavLink
                                    className="sidebar-link"
                                    to="/sell-commission-collections"
                                  >
                                    {t("Sell commission collections")}
                                  </NavLink>
                                </li>
                                {/* <li>
                                  <NavLink
                                    className="sidebar-link"
                                    to="/withdrawals-commission-collections"
                                  >
                                    {t("Withdrawals commission collections")}
                                  </NavLink>
                                </li> */}
                              </ul>
                            </div>
                          </li>
                        )}
                        {permissions["commission"]?.permissions?.fx?.read && (
                          <li>
                            <NavLink
                              className="sidebar-link"
                              to="/commission-fx"
                              data-bs-toggle="collapse"
                              data-bs-target="#demo112"
                            >
                              {t("Fx")}
                            </NavLink>
                            <div className="collapse" id="demo112">
                              <ul className="submenu">
                                <li>
                                  <NavLink
                                    className="sidebar-link"
                                    to="/users-commission-forex"
                                  >
                                    {t("Users commission")}
                                  </NavLink>
                                </li>
                                <li>
                                  <NavLink
                                    className="sidebar-link"
                                    to="/buy-commission-fx"
                                  >
                                    {t("Buy commission collections")}
                                  </NavLink>
                                </li>
                                <li>
                                  <NavLink
                                    className="sidebar-link"
                                    to="/sell-commission-fx"
                                  >
                                    {t("Sell commission collections")}
                                  </NavLink>
                                </li>
                                {/* <li>
                                  <NavLink
                                    className="sidebar-link"
                                    to="/withdrawals-commission-fx"
                                  >
                                    {t("Withdrawals commission collections")}
                                  </NavLink>
                                </li> */}
                              </ul>
                            </div>
                          </li>
                        )}
                        {permissions["commission"]?.permissions?.equity
                          ?.read && (
                          <li>
                            <NavLink
                              className="sidebar-link"
                              to="/commission-equity"
                              data-bs-toggle="collapse"
                              data-bs-target="#demo113"
                            >
                              {t("Equity")}
                            </NavLink>
                            <div className="collapse" id="demo113">
                              <ul className="submenu">
                                <li>
                                  <NavLink
                                    className="sidebar-link"
                                    to="/users-commission-equity"
                                  >
                                    {t("Users commission")}
                                  </NavLink>
                                </li>
                                <li>
                                  <NavLink
                                    className="sidebar-link"
                                    to="/buy-commission-eq"
                                  >
                                    {t("Buy commission collections")}
                                  </NavLink>
                                </li>
                                <li>
                                  <NavLink
                                    className="sidebar-link"
                                    to="/sell-commission-eq"
                                  >
                                    {t("Sell commission collections")}
                                  </NavLink>
                                </li>
                                {/* <li>
                                  <NavLink
                                    className="sidebar-link"
                                    to="/withdrawals-commission-eq"
                                  >
                                    {t("Withdrawals commission collections")}
                                  </NavLink>
                                </li> */}
                              </ul>
                            </div>
                          </li>
                        )}
                      </ul>
                    </div>
                  </li>
                )}

                {false && permissions["margin"]?.status && (
                  <li className="sidebar-item">
                    <NavLink
                      className={({ isActive }) =>
                        isActive
                          ? " sidebar-link waves-effect waves-dark sidebar-link"
                          : "sidebar-link waves-effect waves-dark sidebar-link"
                      }
                      data-bs-toggle="collapse"
                      data-bs-target="#demo9"
                      to={"#"}
                    >
                      <MoneyOff fontSize="small" />
                      <span className="hide-menu">{t("Margin Trade FX")}</span>
                    </NavLink>
                    <div className="collapse" id="demo9">
                      <ul className="submenu">
                        <li>
                          <NavLink className="sidebar-link" to="/margin-status">
                            {t("Status")}
                          </NavLink>
                        </li>
                      </ul>
                    </div>
                  </li>
                )}

                {false && permissions["cashActivity"]?.status && (
                  <li className="sidebar-item">
                    <NavLink
                      className={({ isActive }) =>
                        isActive
                          ? " sidebar-link waves-effect waves-dark sidebar-link"
                          : "sidebar-link waves-effect waves-dark sidebar-link"
                      }
                      data-bs-toggle="collapse"
                      data-bs-target="#demo10"
                      to={"#"}
                    >
                      <AccountBalanceWallet fontSize="small" />
                      <span className="hide-menu">{t("Cash Activity")}</span>
                    </NavLink>
                    <div className="collapse" id="demo10">
                      <ul className="submenu">
                        {permissions["cashActivity"]?.permissions?.crypto
                          ?.read && (
                          <li>
                            <NavLink className="sidebar-link" to="/cash-crypto">
                              {t("Crypto")}
                            </NavLink>
                          </li>
                        )}
                        {permissions["cashActivity"]?.permissions?.fx?.read && (
                          <li>
                            <NavLink className="sidebar-link" to="/cash-fx">
                              {t("Fx")}
                            </NavLink>
                          </li>
                        )}
                        {permissions["cashActivity"]?.permissions?.equity
                          ?.read && (
                          <li>
                            <NavLink className="sidebar-link" to="/cash-equity">
                              {t("Equity")}
                            </NavLink>
                          </li>
                        )}
                      </ul>
                    </div>
                  </li>
                )}

                {/* {permissions["position"]?.status && (
                  <li className="sidebar-item">
                    <NavLink
                      className={({ isActive }) =>
                        isActive
                          ? " sidebar-link waves-effect waves-dark sidebar-link"
                          : "sidebar-link waves-effect waves-dark sidebar-link"
                      }
                      data-bs-toggle="collapse"
                      data-bs-target="#demo11"
                      to={"#"}
                    >
                      <Assessment fontSize="small" />
                      <span className="hide-menu">Position Management</span>
                    </NavLink>
                    <div className="collapse" id="demo11">
                      <ul className="submenu">
                        {permissions["position"]?.permissions?.fx?.read && (
                          <li>
                            <NavLink className="sidebar-link" to="/position-fx">
                              Fx
                            </NavLink>
                          </li>
                        )}
                        {permissions["position"]?.permissions?.equity?.read && (
                          <li>
                            <NavLink
                              className="sidebar-link"
                              to="/position-equity"
                            >
                              Equity
                            </NavLink>
                          </li>
                        )}
                      </ul>
                    </div>
                  </li>
                )} */}

                {permissions["liveOrders"]?.status && (
                  <li className="sidebar-item">
                    <NavLink
                      className={({ isActive }) =>
                        isActive
                          ? " sidebar-link waves-effect waves-dark sidebar-link"
                          : "sidebar-link waves-effect waves-dark sidebar-link"
                      }
                      data-bs-toggle="collapse"
                      data-bs-target="#demoLive"
                      to={"#"}
                    >
                      <AutoGraph fontSize="small" />
                      <span className="hide-menu">{t("Live Orders")}</span>
                    </NavLink>
                    <div className="collapse" id="demoLive">
                      <ul className="submenu">
                        {permissions["liveOrders"]?.permissions?.crypto
                          ?.read && (
                          <li>
                            <NavLink className="sidebar-link" to="/live-orders">
                              {t("Crypto Live Orders")}
                            </NavLink>
                          </li>
                        )}
                        {permissions["liveOrders"]?.permissions?.fx?.read && (
                          <li>
                            <NavLink
                              className="sidebar-link"
                              to="/live-orders-fx"
                            >
                              {t("Fx Live Orders")}
                            </NavLink>
                          </li>
                        )}
                        {permissions["liveOrders"]?.permissions?.equity
                          ?.read && (
                          <li>
                            <NavLink
                              className="sidebar-link"
                              to="/live-orders-eq"
                            >
                              {t("Equity Live Orders")}
                            </NavLink>
                          </li>
                        )}
                      </ul>
                    </div>
                  </li>
                )}

                {/* {permissions["broker"]?.status && (
                  <li className="sidebar-item">
                    <NavLink
                      className={({ isActive }) =>
                        isActive
                          ? "active sidebar-link waves-effect waves-dark sidebar-link"
                          : "sidebar-link waves-effect waves-dark sidebar-link"
                      }
                      to="/broker-account"
                      aria-expanded="false"
                    >
                      <i className="fa fa-user" aria-hidden="true" />
                      <span className="hide-menu">Account</span>
                    </NavLink>
                  </li>
                )} */}

                {permissions["support"]?.status && (
                  <li className="sidebar-item">
                    <NavLink
                      className={({ isActive }) =>
                        isActive
                          ? " sidebar-link waves-effect waves-dark sidebar-link"
                          : "sidebar-link waves-effect waves-dark sidebar-link"
                      }
                      data-bs-toggle="collapse"
                      data-bs-target="#demo13"
                      to="/support"
                      // aria-expanded="false"
                    >
                      {/* <i className="fa fa-comments" aria-hidden="true" /> */}
                      <Forum fontSize="small" />
                      <span className="hide-menu">
                        {t("Support Management")}
                      </span>
                    </NavLink>
                    {/* <div className="collapse" id="demo13">
                      <ul className="submenu">
                        <li>
                          <NavLink
                            className="sidebar-link"
                            to="/support-status"
                          >
                            Status
                          </NavLink>
                        </li>
                      </ul>
                    </div> */}
                  </li>
                )}

                <li className="text-center  upgrade-btn sidebar-link">
                  <button
                    className=" btn_log_out gap-2"
                    target="_blank"
                    onClick={handleShow}
                  >
                    <Logout fontSize="small" />
                    <span className="ml-2">{t("Logout")}</span>
                  </button>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{t("Confirm")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{t("Are you sure you want to logout")}?</Modal.Body>
        <Modal.Footer>
          <Button className="btn" variant="danger" onClick={handleLogout}>
            {t("Logout")}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
export default Sidebar;
