import React, { useState, useEffect } from "react";
import { HeadWrapper } from "../Elements";
import { WithdrawalRequestTable } from "./WithdrawalRequestTable";
import { useTranslation } from "react-i18next";
import {
  useGetWithdrawalQuery,
  useApproveWithdrawalMutation,
} from "../../../redux/withdrawal";
import { handleToast } from "../../utils/handleToast";
import CustomModal from "../../widgets/Modal";
import Button from "../../widgets/Button";

const WithdrawalRequest = () => {
  const { t } = useTranslation();
  const [user, setUser] = useState(null);
  const [isRejected, setIsRejected] = useState(false);
  const [rejectReason, setRejectReason] = useState(""); // New state for textarea value
  const { data, refetch } = useGetWithdrawalQuery();
  const [approveWithdrawal, { isLoading }] = useApproveWithdrawalMutation();

  const handlePopupClose = () => {
    setIsRejected(false);
    setUser(null);
    setRejectReason("");
  };

  const handleReject = (data) => {
    setUser(data);
    setIsRejected(true);
  };

  const handleRequest = async (data, status) => {
    const { tx_id, user_id } = data;
    const result = await approveWithdrawal({
      userId: user_id,
      tx_id,
      status,
      rejectReason,
    });

    handleToast(t, result);
    if (result?.data?.status) {
      refetch();
      handlePopupClose();
    }
  };

  const handleTextareaChange = (e) => {
    setRejectReason(e.target.value);
  };

  return (
    <HeadWrapper title="Withdrawal Request">
      <div className="white-box">
        <WithdrawalRequestTable
          data={data}
          handleRequest={handleRequest}
          handleReject={handleReject}
          isLoading={isLoading}
        />
      </div>
      <CustomModal
        isOpen={isRejected}
        onClose={handlePopupClose}
        title={t("Reject Withdrawal Request?")}
        footer={
          <Button
            className="btn btn-danger"
            variant={"danger"}
            isLoading={isLoading}
            onClick={() => handleRequest(user, "-1")}
          >
            {t("Reject")}
          </Button>
        }
      >
        <div>
          <label className="fw-bold">Reject Reason (optional)</label>
          <br />
          <textarea
            className="w-100 form-control"
            value={rejectReason}
            onChange={handleTextareaChange}
          />
        </div>
      </CustomModal>
    </HeadWrapper>
  );
};

export default WithdrawalRequest;
